import commonFr from '@/assets/locales/fr/common.json';
import woodlockFr from '@/assets/locales/fr/woodlock.json';
import app2Fr from '@/assets/locales/fr/app-2.json';
import illusionFr from '@/assets/locales/fr/illusion.json';

import commonEn from '@/assets/locales/en/common.json';
import woodlockEn from '@/assets/locales/en/woodlock.json';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  fr: {
    translation: {
      ...commonFr,
      ...app2Fr,
      ...woodlockFr,
      ...illusionFr,
    },
  },
  en: {
    translation: {
      ...commonEn,
      ...woodlockEn,
    },
  },
} as const;

i18n.use(LanguageDetector).init({
  resources,
  interpolation: {
    escapeValue: false,
  },
  supportedLngs: ['fr', 'en'],
});

export default i18n;

export type FrenchTranslation = typeof resources.fr.translation;
