import { cn } from '@/utils/cn';
import { PressEvent, usePress } from '@react-aria/interactions';
import { Link } from 'react-router-dom';

const colorsByVariant = {
  primary: cn(
    'bg-gradient-to-r from-[#aa050e] to-[#e7424b]',
    'hover:brightness-110',
    'disabled:opacity-30 disabled:cursor-default disabled:brightness-100 disabled:cursor-not-allowed',
  ),
  secondary:
    'bg-white/20 hover:bg-white/40 disabled:opacity-30 disabled:cursor-default disabled:bg-white/20 disabled:cursor-not-allowed',
};

interface LinkButtonProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> {
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary';
  onClick?: (e: PressEvent) => void;
  onPress?: (e: PressEvent) => void;
  to: string;
  disabled?: boolean;
}

const LinkButton = (props: LinkButtonProps) => {
  const { children, variant = 'primary', className, onClick, onPress, to, ...rest } = props;

  const { pressProps } = usePress({ onPress: onPress || onClick });

  return (
    <Link
      {...pressProps}
      {...rest}
      to={to}
      className={cn(
        'shrink-0 h-14 flex items-center justify-center text-white px-8 uppercase font-semibold transition rounded-sm font-din',
        '[&>*]:pointer-events-none',
        colorsByVariant[variant],
        className,
      )}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
